// @ts-ignore
import React from "react"
import { graphql, PageProps } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import "twin.macro"
import {
  GatsbyContactForm,
  GatsbyContactPerson,
  GatsbyLayout,
  LivingInGreenBanner,
} from "../gatsby-components"
import { Section } from "../layout"
import { SEO, TopContent, Hero } from "../components"
import {
  Table,
  Surface,
  Price,
  ButtonOutline,
  Title,
  StatusBadge,
} from "../elements"
import { SanityGatsbyImage } from "../types"

const CondoTemplate = ({ data }: CondoTemplateProps) => {
  const { condo } = data
  // We assume that referenceNumber always looks like this: A.Something, B.Something, C.Something
  const building = condo.referenceNumber[0]
  return (
    <GatsbyLayout>
      <SEO title={condo.referenceNumber} description={condo.title} />
      {/* Hero */}
      <Hero
        Image={
          <GatsbyImage
            image={condo.titleImage.asset.gatsbyImageData}
            alt={condo.title}
          />
        }
      />
      {/* Intro Text, Table + Expose */}
      <Section paddingTop paddingBottom>
        <TopContent
          title={
            <span tw="max-w-xl inline-block">
              {condo.title}
              <br />
              {condo.referenceNumber}
            </span>
          }
        >
          <StatusBadge status={condo.status} />
          {condo.status === "reserved" && (
            <p tw="mt-2 bg-yellow-100 p-4">
              Für Wohnungen mit dem Status „Warteliste“ tragen wir Sie gerne auf
              unsere Warteliste ein und informieren Sie über
              Statusveränderungen.
            </p>
          )}
          <p tw="mt-8">{condo.text}</p>
          <Table
            tw="w-full mt-8"
            items={[
              {
                label: "Zimmer",
                value: condo.rooms,
              },
              {
                label: "Quadratmeter",
                value: <Surface value={condo.space} />,
              },
              {
                label: "Etage",
                value: condo.floor === 0 ? "EG" : condo.floor,
              },
              { label: "Gebäude", value: building },
              {
                label: "Balkon/ Terrasse",
                value: condo.balconies,
              },
              {
                label: "TG-Parkplatz",
                value: <Price value={condo.priceParkingSpace} />,
              },
              {
                label: "Preis",
                value: (
                  <span tw="font-bold">
                    <Price value={condo.price} />
                  </span>
                ),
              },
            ]}
          />
          <ButtonOutline
            tw="mt-12"
            as="a"
            href={condo.expose.asset.url}
            target="_blank"
          >
            Download Exposé
          </ButtonOutline>
        </TopContent>
      </Section>
      <Section paddingTop paddingBottom hasBG>
        <div tw="container text-center">
          <Title tw="text-center mt-4">Grundriss</Title>
          {condo.floorplans.map((floorplan, key) => (
            <GatsbyImage
              key={key}
              tw="max-w-screen-lg mx-auto mt-8"
              image={floorplan.asset.gatsbyImageData}
              alt={`Floorplan ${key}`}
            />
          ))}
        </div>
      </Section>

      {/* Contact Section */}
      <Section paddingTop paddingBottom>
        <GatsbyContactPerson />
      </Section>
      <Section hasBG paddingTop paddingBottom>
        <GatsbyContactForm />
      </Section>

      {/* Banner */}
      <Section paddingTop paddingBottom>
        <LivingInGreenBanner />
      </Section>
    </GatsbyLayout>
  )
}

export const pageQuery = graphql`
  query ($id: String!) {
    condo: sanityCondo(id: { eq: $id }) {
      title
      text
      referenceNumber
      titleImage {
        asset {
          gatsbyImageData(width: 2560)
        }
      }
      rooms
      space
      floor
      balconies
      price
      status
      priceParkingSpace
      expose {
        asset {
          url
        }
      }
      floorplans {
        asset {
          gatsbyImageData(width: 1280)
        }
      }
      positionImage {
        asset {
          gatsbyImageData(width: 1280)
        }
      }
    }
  }
`

type CondoTemplateProps = PageProps<{
  condo: {
    title: string
    text: string
    status: "reserved" | "open"
    referenceNumber: string
    titleImage: SanityGatsbyImage
    rooms: number
    space: number
    floor: number
    balconies: number
    price: number
    priceParkingSpace: number
    expose: {
      asset: {
        url: string
      }
    }
    floorplans: SanityGatsbyImage[]
    positionImage: SanityGatsbyImage
  }
}>

export default CondoTemplate
